.loader {
  @apply flex items-center justify-center absolute inset-0 pointer-events-none;

  &-progress {
    animation: progress 1.8s linear infinite;

    @apply text-40 text-primary;
  }

  .icon {
    animation: loader 1.5s ease-in-out infinite;
  }
}

.preloader {
  @apply flex flex-wrap items-center justify-center gap-1 text-primary w-16;

  &-square {
    animation: preloader-square 1s ease infinite;

    &:nth-child(2) {
      animation-delay: 0.25s;
    }

    &:nth-child(3) {
      animation-delay: 0.75s;
    }

    &:nth-child(4) {
      animation-delay: 0.5s;
    }


    @apply bg-current opacity-40 w-7 h-7;
  }
}

@keyframes progress {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    stroke: {
      dasharray : 1px, 200px;
      dashoffset: 0;
    }
  }

  50% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -15px;
    }
  }

  100% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -125px;
    }
  }
}

@keyframes preloader-square {

  0%,
  100% {
    @apply opacity-100;
  }

  25%,
  50%,
  75% {
    @apply opacity-40;
  }
}