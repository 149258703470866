// Главный блок
.main {
  &__image {
    object-position: 30%;

    @screen sm {
      @apply object-center;
    }
  }
}

// Экскурсия
.excursion {
  &__content {
    background-image: radial-gradient(537.14% 76.94% at 30.65% 40.18%, rgba(224, 187, 142, 0.50) 0%, transparent 100%);
  }
}