.quantity {
  @apply flex items-center justify-between bg-white rounded-1 border border-solid border-black border-opacity-30 w-full h-12 py-2 px-4;

  &-btn {
    @apply rounded-1 w-7 h-7;
  }

  &-cover {
    @apply w-10;
  }

  &-input {
    @apply bg-transparent text-center border-none h-auto p-0;
  }
}